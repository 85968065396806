import React, { useState, useEffect } from 'react';
import { Container, Table, Row, Col } from 'react-bootstrap';
import moment from 'moment';
import queryString from 'query-string';
import LeMoulinApi from 'app/universal/utils/LeMoulinApi';
import { css } from 'emotion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPrint } from '@fortawesome/free-solid-svg-icons';
import FakeDelivery from 'app/deliveryTool/components/delivery/FakeDelivery';

const RoadmapTable = (props) => {
  const [tableData, setTableData] = useState(null);
  const [loading, setLoading] = useState(true);

  const params = queryString.parse(props.location.search);
  const date = params.date;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await LeMoulinApi.request.roadmap.table.get(date);
        // Trier les roadmaps par heure de départ
        let sortedRoadmaps = [];
        if (response.roadmaps) {
          sortedRoadmaps = [...response.roadmaps].sort((a, b) => {
            if (a.startHour === 'inconnu') return 1;
            if (b.startHour === 'inconnu') return -1;
            return a.startHour.localeCompare(b.startHour);
          });
        }
        setTableData({
          date: date || moment().format('YYYY-MM-DD'),
          roadmaps: sortedRoadmaps
        });
      } catch (error) {
        console.error('Error fetching roadmap table:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [date]);

  const getDeliveryColor = (type) => {
    switch (type) {
      case 'breakfast': return 'green';
      case 'mealtray': return 'gray';
      case 'caterer': return 'blue';
      default: return 'red';
    }
  };

  const Legend = () => (
    <Row className="mb-4">
      <Col>
        <h5 className={css(styles.legendTitle)}>Légende des prestations :</h5>
        <div className={css(styles.legend)}>
          <span style={{ color: 'green' }}>■</span> Petit-déjeuner
          <span style={{ color: 'gray', marginLeft: '15px' }}>■</span> Plateau repas
          <span style={{ color: 'blue', marginLeft: '15px' }}>■</span> Traiteur
          <span style={{ color: 'red', marginLeft: '15px' }}>■</span> Inconnu
        </div>
      </Col>
    </Row>
  );

  return (
    <div>
      {loading || !tableData ? (
        <Container>
          <FakeDelivery />
        </Container>
      ) : (
        <>
          <h1 className={css(styles.title)}>
            Tableau des tournées du {moment(tableData.date).format('DD/MM/YY')}
          </h1>
          
          <div className={css(styles.print)} onClick={() => window.print()}>
            <FontAwesomeIcon icon={faPrint} />
          </div>

          <Legend />

          <Table striped bordered hover>
            <thead>
              <tr>
                <th>H départ</th>
                <th>Tournée</th>
                <th>Livreur</th>
                <th>Prestations</th>
                <th>Véhicule</th>
              </tr>
            </thead>
            <tbody>
              {tableData.roadmaps.map((roadmap, index) => (
                <tr key={index}>
                  <td style={{fontSize: '2rem'}}>{roadmap.startHour}</td>
                  <td>{roadmap.name}</td>
                  <td style={{fontSize: '2rem'}}>{roadmap.deliveryMan}</td>
                  <td style={{fontSize: '2rem'}}>
                    {roadmap.deliveries.map((delivery, idx) => (
                      <span key={idx}>
                        <span style={{ color: getDeliveryColor(delivery.type), fontWeight: 'bold' }}>
                          {delivery.number}
                        </span>
                        {idx < roadmap.deliveries.length - 1 ? ' - ' : ''}
                      </span>
                    ))}
                  </td>
                  <td>{roadmap.vehicleName ? roadmap.vehicleName : '-'}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
    </div>
  );
};

const styles = {
  title: {
    color: '#b64e4e',
    textAlign: 'center',
    fontSize: '1.6rem',
    marginBottom: '2rem'
  },
  print: {
    '@media print': {
      display: 'none'
    },
    cursor: 'pointer',
    backgroundColor: 'white',
    position: 'fixed',
    top: '3%',
    width: '60px',
    height: '60px',
    paddingTop: '9px',
    right: '3%',
    borderRadius: '50%',
    border: 'none',
    color: '#555',
    fontSize: '1.5rem',
    textAlign: 'center',
    padding: '0.6rem 0.2rem',
    transition: '300ms',
    boxShadow: '2px 2px 10px #ccc',
    ':hover': {
      textDecoration: 'none',
      color: 'white',
      backgroundColor: '#bbb',
      transition: '300ms'
    }
  },
  legendTitle: {
    fontSize: '1rem',
    color: '#666',
    marginBottom: '0.5rem'
  },
  legend: {
    fontSize: '0.9rem',
    color: '#666',
    '& span': {
      fontSize: '1.2rem',
      marginRight: '5px'
    }
  }
};

export default RoadmapTable; 