import React from "react";
import { Form, Row, Col } from "react-bootstrap";

const ProductionBlock = ({ hotDishes, prodInfo, additionalProdInfo, extraMeals, deliveryNumber, onChange }) => (
  <div>
    <h3>Production</h3>
    <Row
      style={{
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        border: "1px solid lightgray",
        borderRadius: "10px",
        margin: "10px 0",
      }}
    >
      <Col xs={6} md="6">
        <Form.Group controlId="formProdInfo">
          <Form.Label>Info prod</Form.Label>
          <br/><b>{prodInfo ? prodInfo : "NC"}</b>
        </Form.Group>
      </Col>
      <Col xs={6} md="6">
        <Form.Check
          type="checkbox"
          label="Chaud"
          checked={hotDishes}
          onChange={(e) => onChange("hotDishes", e.target.checked)}
        />
      </Col>
      <Col xs={6} md="6">
        <Form.Group controlId="formAdditionalProdInfo">
          <Form.Label>Info prod info en plus</Form.Label>
          <div className="only-web">
            <Form.Control
              as="textarea"
              value={additionalProdInfo}
              onChange={(e) => onChange("additionalProdInfo", e.target.value)}
            />
          </div>
          <div className="only-print">
            <br/><b>{additionalProdInfo ? additionalProdInfo : "NC"}</b>
          </div>
        </Form.Group>
      </Col>
      <Col xs={6} md="6">
        <Form.Group controlId="formExtraMeals">
          <Form.Label>Repas extra</Form.Label>
          <div className="only-web">
            <Form.Control
              type="text"
              value={extraMeals}
              onChange={(e) => onChange("extraMeals", e.target.value)}
            />
          </div>
          <div className="only-print">
            <br/><b>{extraMeals ? extraMeals : "0"}</b>
          </div>
        </Form.Group>
      </Col>
    </Row>
  </div>
);

export default ProductionBlock;