import React, {useEffect} from 'react'

import { CSSTransitionGroup } from 'react-transition-group'
import {Container, Col, Row} from 'react-bootstrap'
import { css } from 'emotion'

import { useRoadmaps } from 'app/deliveryTool/context/RoadmapsProvider'
import { useFilters } from 'app/deliveryTool/context/FiltersProvider'

import RoadMapDeliveryMan from 'app/deliveryTool/pages/planning/RoadMapDeliveryMan'
import DirectionsMap from 'app/universal/map/DirectionsMap'
import DeliveryRecoveryContainer from 'app/deliveryTool/containers/DeliveryRecoveryContainer'
import FakeDelivery from 'app/deliveryTool/components/delivery/FakeDelivery'

const RoadMaps = (props) => {

  const rmData  = useRoadmaps();
  const filters = useFilters();

  const {
    roadmaps,
    deliveries,
    recoveries,
    loading
  } = rmData

  const onMarkerClick = (id) => {
    filters.setShowDelivery(id);
  }

  // Quand la date change, on revient à la liste
  useEffect(() => {

    props.router.push({
      pathname: '/planning',
      search: window.location.search,
    });

  }, [filters.date, props.router])

  const styles = {
    title: {
      color: 'orange',
      fontSize: '1.2rem',
      margin: '1rem 0 0.5rem 0',
      fontWeight: 'bold',
      textTransform: 'uppercase'
    },
    delivery: {
      background: 'white',
      margin: '10px 0',
      padding: '10px'
    },
    para: {
      marginBottom: '0px'
    },
    scroll: {
      height: '88vh',
      overflowY: 'scroll'
    },
  }

  return (
    <div>
      <Row>
        <Col xs={12} sm={12} md={8} className={css(styles.scroll)} id="listing">
          {loading ?
             <FakeDelivery />
          :
            <CSSTransitionGroup
              transitionName="fade"
              transitionEnterTimeout={300}
              transitionLeaveTimeout={200}>
                {((roadmaps.length === 0) && (deliveries.length === 0)) ?
                  <Container className={css(styles.delivery)} fluid>
                    <Row>
                      <Col xs={12} md={12}>
                        <p className={css(styles.para)}>Il n'y a pas encore de livraison pour ces dates.</p>
                      </Col>
                    </Row>
                   </Container>
                  : null }

                 {/* Changement de la key par roadmap.id au lieu de index + '001', les inputs se mettent pas à jour sinon au changement de date (Hypothese, vu que la clé etait la meme d'une date à l'autre tout ne devait pas se reload) */}
                {roadmaps.map((roadmap, index) =>
                  <div key={'r'+roadmap.id}>
                    <RoadMapDeliveryMan roadmap={roadmap} key={index} />
                  </div>
                )}
                <div>
                  {deliveries.length > 0 || recoveries.length > 0 ? <h2 className={css(styles.title)}>Livraisons non attribuées</h2> : null }

                  {deliveries.map((delivery, index) =>
                    <div key={delivery.id}>
                      <DeliveryRecoveryContainer type="delivery" delivery={delivery} key={delivery.id}/>
                    </div>
                  )}
                  {recoveries.map((recovery, index) =>
                    <div key={recovery.id}>
                      <DeliveryRecoveryContainer type="recovery" delivery={recovery} key={recovery.id}/>
                    </div>
                  )}
                </div>
            </CSSTransitionGroup>
          }
        </Col>
        <Col xs={12} sm={12} md={4}>
          {loading ?
              <FakeDelivery />
            :
              <DirectionsMap onMarkerClick={onMarkerClick} filters={filters} deliveries={deliveries} recoveries={recoveries} roadmaps={roadmaps}/>
          }
        </Col>
      </Row>
    </div>
  )

}

export default RoadMaps
