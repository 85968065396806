import React, { useState, useEffect } from "react";
import moment from "moment";
import styled from '@emotion/styled'
import { Accordion, Button, Modal } from "react-bootstrap";
import Delivery from "./Delivery";
import { Element } from 'react-scroll';
import DirectionsMap from 'app/universal/map/DirectionsMap'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faComment } from '@fortawesome/free-solid-svg-icons'

const Roadmap = (props) => {
  
  const { roadmap, handleCommentaireChange, vehicles, updateRoadmapVehicle } = props;
  const [commentaire, setCommentaire] = useState(roadmap?.delivery_man_comment || '');
  const [selectedVehicle, setSelectedVehicle] = useState(roadmap?.vehicle?.id || '');
  const [isLoading, setIsLoading] = useState(false);
  const [showCommentModal, setShowCommentModal] = useState(false);

  useEffect(() => {
    setCommentaire(roadmap?.delivery_man_comment || '');
    setSelectedVehicle(roadmap?.vehicle?.id || '');
  }, [roadmap]);

  const handleSubmit = () => {
    handleCommentaireChange(roadmap.id, commentaire);
    setShowCommentModal(false);
  };

  const handleVehicleChange = async (e) => {
    updateRoadmapVehicle(roadmap.id, e.target.value);
  };

  return (
    <Element name={roadmap.name + roadmap.id} className="element">
      <Title color={"#02B39E"}>
        <b>TOURNÉE {roadmap.name}</b><br />
        <small>{moment(roadmap.date).format("DD/MM/YYYY")}</small><br />
        <small>Départ : {moment(roadmap?.manual_start_hour ? roadmap.manual_start_hour : roadmap.start_hour).format("HH:mm")} - {Number(roadmap.distance / 1000).toFixed(1)}km</small>
        {roadmap.admin_comment && (
          <small><br/>
            <FontAwesomeIcon icon={faComment} /> Admin : {roadmap.admin_comment}
          </small>
        )}
        <small><br/>
          {commentaire && <><FontAwesomeIcon icon={faComment} /> Livreur : {commentaire} - </>}
          <span
            variant="outline-primary" 
            className="mb-3 w-100"
            style={{ cursor: 'pointer', textDecoration: 'underline' }}
            onClick={() => setShowCommentModal(true)}
          >
            {!commentaire ? "Ajouter un commentaire" : "Modifier"}
          </span>
        </small>
        
      </Title>
      <div>
        <VehicleSelector 
          value={selectedVehicle} 
          onChange={handleVehicleChange}
          disabled={isLoading}
        >
          <option value="">Sélectionner un véhicule...</option>
          {vehicles.map(vehicle => {
            // Formater les noms des livreurs favoris
            const deliveryMenNames = vehicle.deliveryMen && vehicle.deliveryMen.length > 0
              ? vehicle.deliveryMen.map(dm => dm.firstname).join(', ')
              : '';
              
            return (
              <option key={vehicle.id} value={vehicle.id}>
                {vehicle.name} {deliveryMenNames && `(${deliveryMenNames})`}
              </option>
            );
          })}
        </VehicleSelector>
      </div>
      
      <Modal show={showCommentModal} onHide={() => setShowCommentModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Commentaire livreur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <textarea 
            value={commentaire}
            onChange={(e) => setCommentaire(e.target.value)}
            placeholder="Mettre un commentaire livreur sur l'itinéraire ..."
            className="form-control"
            rows={4}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCommentModal(false)}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Sauvegarder
          </Button>
        </Modal.Footer>
      </Modal>

      {roadmap && <MapCss>
        <DirectionsMap 
          roadmap={roadmap}
          filters={{ showRoadmap: roadmap.id }} 
          isLoading={false}
        />
      </MapCss>}

      {roadmap.deliveries.map((delivery, index) => (
          <Accordion className="mt-3" defaultActiveKey="0" key={index}>
           <Delivery delivery={delivery} roadmap={roadmap}/>
          </Accordion>
        ))}
        <Button
          href={"https://waze.com/ul?ll=45.76176834106445,4.9156718254089355&navigate=no"}
          variant="primary"
          target="_blank"
          block
        >
          Retour au Moulin (Waze)
        </Button>
    </Element>
  );
};

const Title = styled.div`
  text-align: center;
  border-radius: 4px;
  background-color: ${props => (props.color ? props.color : 'white')};
  width: 100%;
  border: thin solid ${props => (props.color ? props.color : 'white')};
  color: white;
  padding: 0.5rem 0.5rem;
  white-space: nowrap;
  margin: 1rem 0;
`

const MapCss = styled.div`
  width: 100%;
  text-align: center;
  position: relative;
  height: 200px;
  border-radius: 4px;
  background-color: #ddd;
  overflow: hidden;
`

const VehicleSelector = styled.select`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 1rem;
  background-color: white;
`

export default Roadmap;
