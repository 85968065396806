import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Table, Button, Form, Row, Col, Container, Badge } from "react-bootstrap";
import { useParams } from "react-router-dom";
import Layout from "app/universal/layout/Layout";
import Loader from "app/deliveryTool/components/delivery/FakeDelivery";
import {
  getTenderCatalog,
  updateTenderCatalog,
  getTenders,
  resetTenderCatalog, 
  resetTenderCatalogRanges
} from "state/tender/actions";
import { Link } from "react-router-dom";
import CurrencyComponent from '../multiOrders/CurrencyComponent';
import swal from "sweetalert";

const TenderCatalogItemsManager = () => {
  const { tenderId } = useParams();
  const dispatch = useDispatch();

  const tenderCatalogState = useSelector((state) => state.tender.tenderCatalog);
  const tendersState = useSelector((state) => state.tender.tenders);

  const { isLoading } = tenderCatalogState;
  const tender = useSelector((state) =>
    tendersState.data.find((tender) => tender.id === parseInt(tenderId))
  );

  const [items, setItems] = useState(
    tenderCatalogState?.data?.items
      ? tenderCatalogState.data.items.map((item, index) => ({
          ...item,
          originalIndex: index,
        }))
      : []
  );
  const [ranges, setRanges] = useState(
    tenderCatalogState?.data?.ranges ? tenderCatalogState.data.ranges : []
  );

  const hasCatalogId = tenderCatalogState?.data?.id;

  const [filters, setFilters] = useState({
    name: "",
    type: "",
    noAvailableGroup: false,
    noPricing: false,
    showType: "all",
    active: "active"
  });

  const [localPrices, setLocalPrices] = useState({});

  useEffect(() => {
    const initialPrices = {};
    items.forEach((item) => {
      initialPrices[item.originalIndex] = item.price;
      ranges.forEach((range, index) => {
        let pricing = item.pricings.find((pricing) => pricing.range_id === range.id);
        initialPrices[`${item.originalIndex}-${index}`] = pricing && pricing.price;
      });
    });
    setLocalPrices(initialPrices);
  }, [items]);

  const handleLocalPriceChange = (key, value, range, itemIndex) => {
    setLocalPrices((prevPrices) => ({
      ...prevPrices,
      [key]: value,
    }));
    if (range) {
      handleRangePriceChange(itemIndex, range.min_persons, range.max_persons, range.id, value);
    }
    else {
      const newItems = items.map((item, i) =>
        i === key ? { ...item, price: value, originalIndex: i } : item
      );
      setItems(newItems);
    }
  };

  const handleRangePriceChange = (itemIndex, min, max, range_id, value) => {
    const pricingIndex = items[itemIndex].pricings.findIndex(
      (pricing) => pricing.range_id === range_id
    );
    let newItems = [];
    if (pricingIndex === -1) {
      newItems = items.map((item, i) =>
        i === itemIndex
          ? {
              ...item,
              pricings: [...item.pricings, { price: value, range_id: range_id, min_persons: min, max_persons: max }],
              originalIndex: i
            }
          : {...item, originalIndex: i}
      );
    } else {
      newItems = items.map((item, i) =>
        i === itemIndex
          ? {
              ...item,
              pricings: item.pricings.map((pricing, pi) =>
                pi === pricingIndex ? { ...pricing, price: value, range_id: range_id, min_persons: min, max_persons: max } : pricing
              ),
              originalIndex: i
            }
          : {...item, originalIndex: i}
      );
    }
    setItems(newItems);
  };

  const handleSave = () => {
    const newCatalog = {
      ...tenderCatalogState.data,
      items,
    };
    dispatch(updateTenderCatalog(tenderId, newCatalog));
  };

  // Si l'id du tender change, on reset le state
  useEffect(() => {
    dispatch(resetTenderCatalog());
    dispatch(resetTenderCatalogRanges());
  }
  , [dispatch, tenderId]);

  // Recupère les tenders si pas recup
  useEffect(() => {
    if (!tendersState.isInitialized && !tendersState.isLoading) {
      dispatch(getTenders());
    }
  }, [dispatch, tendersState]);

  // Catalogue si pas init
  useEffect(() => {
    if (!tenderCatalogState.isInitialized && !tenderCatalogState.isLoading) {
      dispatch(getTenderCatalog(tenderId)).then((resp) => {
        if (resp?.error || resp?.errors) {
          let infoError = Array.isArray(resp?.errors) ? resp.errors.toString() : null;
          infoError = typeof resp?.error === 'string' ? (infoError ? infoError + ' ' + resp.error : resp.error) : infoError;
        
          swal({
            title: "Attention ",
            text: "Impossible de récupérer le catalogue de l'appel d'offre. Contactez le Pôle web en leur communiquant cette information : " + infoError,
            icon: "warning",
            dangerMode: true,
          })    
        }
      }).catch((error) => {
        swal({
          title: "Attention ",
          text: "Impossible de récupérer le catalogue de l'appel d'offre. Contactez le Pôle web",
          icon: "warning",
          dangerMode: true,
        })  
      });
    }
  }, [dispatch, tenderCatalogState.isInitialized, tenderId]);

  // Met à jour le state items si le catalogue change
  useEffect(() => {
    if (tenderCatalogState?.data?.items) {
      setItems(tenderCatalogState.data.items.map((item, index) => ({
        ...item,
        originalIndex: index,
      })));
    }
    if (tenderCatalogState?.data?.ranges) {
      setRanges(tenderCatalogState.data.ranges);
    }
  }, [tenderCatalogState.data]);

  const [filteredProductItems, setFilteredProductItems] = useState([]);
  const [filteredGroupItems, setFilteredGroupItems] = useState([]);
  const [filteredPackageItems, setFilteredPackageItems] = useState([]);

  useEffect(() => {
    // Filtrer les éléments de produits
    setFilteredProductItems(items
    .filter(item => (filters.showType === "all" || filters.showType === "product") && item.type === "product")
    .filter(item => {
      const nameMatch = item.name.toLowerCase().includes(filters.name.toLowerCase());
      const typeMatch = filters.type ? item.types.includes(filters.type) : true;
      const noPricingMatch = filters.noPricing
        ? !item.pricings.every(pricing => pricing.price !== null && pricing.price !== "")
        : true;
      const activeMatch = filters.active === "all" 
        ? true 
        : filters.active === "active" 
          ? item.is_active_item 
          : !item.is_active_item;
      return nameMatch && typeMatch && noPricingMatch && activeMatch;
    }));

    // Filtrer les éléments de groupes de produits
    setFilteredGroupItems(items
    .filter(item => (filters.showType === "all" || filters.showType === "group") && item.type === "group")
    .filter(item => {
      const nameMatch = item.name.toLowerCase().includes(filters.name.toLowerCase());
      const typeMatch = filters.type ? item.types.includes(filters.type) : true;
      const noAvailableGroupMatch = filters.noAvailableGroup
        ? item.is_up_to_date
        : true;
      const noPricingMatch = filters.noPricing
        ? !item.pricings.every(pricing => pricing.price !== null && pricing.price !== "")
        : true;
      const activeMatch = filters.active === "all" 
        ? true 
        : filters.active === "active" 
          ? item.is_active_item
          : !item.is_active_item;
      return nameMatch && typeMatch && noAvailableGroupMatch && noPricingMatch && activeMatch;
    }));

    // Filtrer les éléments de forfaits
    setFilteredPackageItems(items
    .filter(item => (filters.showType === "all" || filters.showType === "package") && item.type === "package")
    .filter(item => {
      const nameMatch = item.name.toLowerCase().includes(filters.name.toLowerCase());
      const typeMatch = filters.type ? item.types.includes(filters.type) : true;
      const noAvailablePackageMatch = filters.noAvailableGroup
        ? item.is_up_to_date
        : true;
      const noPricingMatch = filters.noPricing
        ? !item.pricings.every(pricing => pricing.price !== null && pricing.price !== "")
        : true;
      const activeMatch = filters.active === "all" 
        ? true 
        : filters.active === "active" 
          ? item.is_active_item 
          : !item.is_active_item;
      return nameMatch && typeMatch && noAvailablePackageMatch && noPricingMatch && activeMatch;
    }));
  }, [items, filters]);

  const prestationTypeTranslations = {
    'breakfast': 'PDJ',
    'caterer': 'TR',
    'mealtray': 'PR'
  };

  return (
    <Layout reduxIsUsed={true}>
      <Container fluid>
        <h2 className="text-center py-3"><small><Link to={'/appel_offre'}><u>Liste AO</u></Link> {`> Prix ${tender?.name}`}</small></h2>
        {isLoading ? (
          <Loader />
        ) : (
          <div>
            <Form>
              <Row className="align-items-center">
                <Col>
                  <Form.Group controlId="filterName">
                    <Form.Label>Nom</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Rechercher par nom"
                      value={filters.name}
                      onChange={(e) =>
                        setFilters({ ...filters, name: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="filterType">
                    <Form.Label>Type de produit</Form.Label>
                    <Form.Control
                      as="select"
                      value={filters.type}
                      onChange={(e) =>
                        setFilters({ ...filters, type: e.target.value })
                      }
                    >
                      <option value="">Tous les types</option>
                      <option value="breakfast">Dej</option>
                      <option value="caterer">Cocktail</option>
                      <option value="mealtray">PR</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="filterShowType">
                    <Form.Label>Afficher</Form.Label>
                    <Form.Control
                      as="select"
                      value={filters.showType}
                      onChange={(e) =>
                        setFilters({ ...filters, showType: e.target.value })
                      }
                    >
                      <option value="all">Produits/Groupes/Forfaits</option>
                      <option value="product">Produits uniquement</option>
                      <option value="group">Groupes uniquement</option>
                      <option value="package">Forfaits uniquement</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="filterNoAvailableGroup">
                    <Form.Check
                      type="checkbox"
                      label="Exclure les groupes de produits dépassés"
                      checked={filters.noAvailableGroup}
                      onChange={(e) =>
                        setFilters({ ...filters, noAvailableGroup: e.target.checked })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="filterNoPricing">
                    <Form.Check
                      type="checkbox"
                      label="Palier non renseigné"
                      checked={filters.noPricing}
                      onChange={(e) =>
                        setFilters({ ...filters, noPricing: e.target.checked })
                      }
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="filterActive">
                    <Form.Label>Statut</Form.Label>
                    <Form.Control
                      as="select"
                      value={filters.active}
                      onChange={(e) =>
                        setFilters({ ...filters, active: e.target.value })
                      }
                    >
                      <option value="active">Actifs</option>
                      <option value="inactive">Inactifs</option>
                    </Form.Control>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Produits</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredProductItems &&
                  filteredProductItems.map((item, i) => (
                    <tr key={item.id}>
                      <td style={{width: "400px"}}>
                        <div>{item.name}</div>
                        {item.types.map(type => 
                          <Badge variant={prestationTypeTranslations[type] === "PR" ? "success" : prestationTypeTranslations[type] === "TR" ? "warning" : prestationTypeTranslations[type] === "PDJ" ? "primary" : ""} key={type} className="mr-1"> 
                            {prestationTypeTranslations[type]}
                          </Badge>
                        )}
                        <Form.Control
                          type="text"
                          value={item.renammed || ""}
                          onChange={(e) => {
                            const newItems = [...items];
                            newItems[item.originalIndex].renammed = e.target.value;
                            setItems(newItems);
                          }}
                          placeholder="Nom alternatif"
                        />
                      </td>
                      <td>
                        <div className="horizontal-scroll-container">
                            <div className="mb-2 horizontal-scroll-item">
                              <strong>€ - Hors palier</strong><br/>
                              <Badge variant="success" className="mr-1">PR</Badge>
                              <Badge variant="warning" className="mr-1">TR</Badge>
                              <Badge variant="primary" className="mr-1">PDJ</Badge>
                              <CurrencyComponent
                                initialPrice={localPrices[item.originalIndex] ?? null}
                                onPriceChange={(value) => handleLocalPriceChange(item.originalIndex, value)}
                                nullable={true}
                              />
                            </div>
                          {ranges.map((range, rangeIndex) => (
                            <div className="mb-2 horizontal-scroll-item">
                              <strong>{`€: ${range.min} à ${range.max} pers`}</strong><br/>
                              {range.prestation_types.map(type => 
                                <Badge variant={prestationTypeTranslations[type] === "PR" ? "success" : prestationTypeTranslations[type] === "TR" ? "warning" : prestationTypeTranslations[type] === "PDJ" ? "primary" : ""} key={type} className="mr-1"> 
                                  {prestationTypeTranslations[type]}
                                </Badge>
                              )}
                              <CurrencyComponent
                                initialPrice={localPrices[`${item.originalIndex}-${rangeIndex}`] ?? null}
                                onPriceChange={(value) => handleLocalPriceChange(`${item.originalIndex}-${rangeIndex}`, value, range, item.originalIndex)}
                                nullable={true}
                              />
                            </div>
                          ))}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Forfaits</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredPackageItems &&
                  filteredPackageItems.map((item, i) => (
                    <tr key={item.id}>
                      <td style={{width: "400px"}}>
                        <div>{item.name}</div>
                        {item.types.map(type => 
                          <Badge variant={prestationTypeTranslations[type] === "PR" ? "success" : prestationTypeTranslations[type] === "TR" ? "warning" : prestationTypeTranslations[type] === "PDJ" ? "primary" : ""} key={type} className="mr-1"> 
                            {prestationTypeTranslations[type]}
                          </Badge>
                        )}
                        <Form.Control
                          type="text"
                          value={item.renammed || ""}
                          onChange={(e) => {
                            const newItems = [...items];
                            newItems[item.originalIndex].renammed = e.target.value;
                            setItems(newItems);
                          }}
                          placeholder="Nom alternatif"
                        />
                      </td>
                      <td>
                        <div className="horizontal-scroll-container">
                            <div className="mb-2 horizontal-scroll-item">
                              <strong>€ - Hors palier</strong><br/>
                              <Badge variant="success" className="mr-1">PR</Badge>
                              <Badge variant="warning" className="mr-1">TR</Badge>
                              <Badge variant="primary" className="mr-1">PDJ</Badge>
                              <CurrencyComponent
                                initialPrice={localPrices[item.originalIndex] ?? null}
                                onPriceChange={(value) => handleLocalPriceChange(item.originalIndex, value)}
                                nullable={true}
                              />
                            </div>
                          
                          {ranges.map((range, rangeIndex) => (
                              <div className="mb-2 horizontal-scroll-item">
                                <strong>{`€: ${range.min} à ${range.max} pers`}</strong><br/>
                                {range.prestation_types.map(type => 
                                  <Badge variant={prestationTypeTranslations[type] === "PR" ? "success" : prestationTypeTranslations[type] === "TR" ? "warning" : prestationTypeTranslations[type] === "PDJ" ? "primary" : ""} key={type} className="mr-1"> 
                                    {prestationTypeTranslations[type]}
                                  </Badge>
                                )}
                                <CurrencyComponent
                                  initialPrice={localPrices[`${item.originalIndex}-${rangeIndex}`] ?? null}
                                  onPriceChange={(value) => handleLocalPriceChange(`${item.originalIndex}-${rangeIndex}`, value, range, item.originalIndex)}
                                  nullable={true}
                                />
                              </div>
                           
                          ))}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Groupes de produit</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {filteredGroupItems &&
                  filteredGroupItems.map((item, i) => (
                    <tr key={item.id}>
                      <td style={{width: "400px"}}>
                        <div>{item.name}</div>
                        {item.types.map(type => 
                          <Badge variant={prestationTypeTranslations[type] === "PR" ? "success" : prestationTypeTranslations[type] === "TR" ? "warning" : prestationTypeTranslations[type] === "PDJ" ? "primary" : ""} key={type} className="mr-1"> 
                            {prestationTypeTranslations[type]}
                          </Badge>
                        )}
                        <Form.Control
                          type="text"
                          value={item.renammed || ""}
                          onChange={(e) => {
                            const newItems = [...items];
                            newItems[item.originalIndex].renammed = e.target.value;
                            setItems(newItems);
                          }}
                          placeholder="Nom alternatif"
                        />
                      </td>
                      <td>
                        <div className="horizontal-scroll-container">
                            <div className="mb-2 horizontal-scroll-item">
                              <strong>€ - Hors palier</strong><br/>
                              <Badge variant="success" className="mr-1">PR</Badge>
                              <Badge variant="warning" className="mr-1">TR</Badge>
                              <Badge variant="primary" className="mr-1">PDJ</Badge>
                              <CurrencyComponent
                                initialPrice={localPrices[item.originalIndex] ?? null}
                                onPriceChange={(value) => handleLocalPriceChange(item.originalIndex, value)}
                                nullable={true}
                              />
                            </div>
                          {ranges.map((range, rangeIndex) => (
                            <div className="mb-2 horizontal-scroll-item">
                              <strong>{`€: ${range.min} à ${range.max} pers`}</strong><br/>
                              {range.prestation_types.map(type => 
                                <Badge variant={prestationTypeTranslations[type] === "PR" ? "success" : prestationTypeTranslations[type] === "TR" ? "warning" : prestationTypeTranslations[type] === "PDJ" ? "primary" : ""} key={type} className="mr-1"> 
                                  {prestationTypeTranslations[type]}
                                </Badge>
                              )}
                              <CurrencyComponent
                                initialPrice={localPrices[`${item.originalIndex}-${rangeIndex}`] ?? null}
                                onPriceChange={(value) => handleLocalPriceChange(`${item.originalIndex}-${rangeIndex}`, value, range, item.originalIndex)}
                                nullable={true}
                              />
                            </div>
                          ))}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
            {!hasCatalogId ? 
              <Link to={`/appel_offre_prix/${tenderId}/palier`}>
                <Button variant="secondary">Editer les paliers</Button>
              </Link>
              :
              <Button variant="success" onClick={handleSave}>
                Sauvegarder
              </Button>
            }
          </div>
        )}
        </Container>
    </Layout>
  );
};

export default TenderCatalogItemsManager;
