import React from 'react'
import { css } from 'emotion'
import { useRoadmaps } from 'app/deliveryTool/context/RoadmapsProvider'

/**
 * Composant pour sélectionner un véhicule pour une tournée
 */
const VehicleSelector = (props) => {
  const {
    handleChange,
    defaultVehicleId,
    hideAll
  } = props

  const rmData = useRoadmaps();

  // Fonction pour formater les noms des livreurs favoris
  const formatDeliveryMenNames = (deliveryMen) => {
    if (!deliveryMen || deliveryMen.length === 0) return '';
    
    return deliveryMen.map(dm => dm.firstname).join(', ');
  };

  return (
    <select className={css(styles.filterSelect)} onChange={handleChange} defaultValue={defaultVehicleId || ""}>
      {(hideAll !== true || defaultVehicleId === null) && <option value="">Sélectionner un véhicule...</option>}
      {rmData.vehicles.map((vehicle, index) =>
        <option value={vehicle.id} key={vehicle.id}>
          {vehicle.name} 
          {vehicle.deliveryMen && vehicle.deliveryMen.length > 0 && ` (${formatDeliveryMenNames(vehicle.deliveryMen)})`}
        </option>
      )}
    </select>
  )
}

const styles = {
  filterSelect: {
    padding: '0.5rem',
    borderRadius: '4px',
    border: '1px solid #ccc',
    marginBottom: '1rem',
    width: '100%'
  }
}

export default VehicleSelector 