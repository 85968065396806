import React from 'react'

import { css } from 'emotion'
import {Container, Row, Col} from 'react-bootstrap'
import { faMap } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useFilters } from 'app/deliveryTool/context/FiltersProvider'

import DeliveryRecoveryContainer from 'app/deliveryTool/containers/DeliveryRecoveryContainer'

const RoadMapDeliveryMan = (props) => {

  const {
    roadmap
  } = props

  const filters = useFilters();

  const onClick = (event) => {
    event.preventDefault();
    filters.setShowRoadmap(roadmap.id)
  }

  return (

    <div>
      <h2 className={css(styles.title)}>Tournée {roadmap.name} - {roadmap.delivery_man && roadmap.delivery_man.firstname} - {roadmap.vehicle && roadmap.vehicle.name} <p onClick={onClick} className={css(styles.map)}><FontAwesomeIcon icon={faMap} /></p></h2>
      
      {(roadmap.admin_comment || roadmap.delivery_man_comment) && (
        <div className={css(styles.comments)}>
          {roadmap.admin_comment && (
            <div className={css(styles.comment)}>
              <span className={css(styles.commentLabel)}>Commentaire admin :</span>
              <p className={css(styles.commentText)}>{roadmap.admin_comment}</p>
            </div>
          )}
          {roadmap.delivery_man_comment && (
            <div className={css(styles.comment)}>
              <span className={css(styles.commentLabel)}>Commentaire livreur :</span>
              <p className={css(styles.commentText)}>{roadmap.delivery_man_comment}</p>
            </div>
          )}
        </div>
      )}

      {roadmap.deliveries.length > 0
        ?
        <div>
          {roadmap.deliveries.map((rmDelivery, index) =>
            <div key={index}>
              {rmDelivery.recovery ?
                <DeliveryRecoveryContainer type="recovery" delivery={rmDelivery.delivery} roadmapName={roadmap.name} sort={rmDelivery.sort} colorDeliveryMan={roadmap.delivery_man ? roadmap.delivery_man.color : 'green'} roadmapId={roadmap.id}/>
                :
                <DeliveryRecoveryContainer type="delivery" delivery={rmDelivery.delivery} colorDeliveryMan={roadmap.delivery_man ? roadmap.delivery_man.color : 'green'} roadmapName={roadmap.name} sort={rmDelivery.sort} roadmapId={roadmap.id} />
              }
            </div>
          )}
        </div>
         :
         <Container className={css(styles.delivery)} fluid>
           <Row>
             <Col xs={12} md={12}>
               <p className={css(styles.para)}>Il n'y a pas encore de livraison attribuée à cette tournée</p>
             </Col>
           </Row>
          </Container>
      }

    </div>

  )

}

RoadMapDeliveryMan.propTypes = {

}


const styles = {
  title: {
    color: '#484b4c',
    fontSize: '1.2rem',
    margin: '1rem 0 0.5rem 0',
    fontWeight: 'bold',
    textTransform: 'uppercase'
  },
  comments: {
    backgroundColor: 'white',
    border: '1px #dedede solid',
    padding: '0.5rem',
    marginBottom: '1rem',
    borderRadius: '4px',
  },
  comment: {
    marginBottom: '0.5rem',
  },
  commentLabel: {
    fontWeight: 'bold',
    fontSize: '0.9rem',
  },
  commentText: {
    margin: '0.2rem 0 0 0',
    fontSize: '0.9rem',
  },
  delivery: {
    background: 'white',
    margin: '10px 0',
    padding: '10px'
  },
  para: {
    marginBottom: '0px'
  },
  map: {
    marginBottom: '0px',
    backgroundColor: '#429853',
    display: 'inline-block',
    color: 'white',
    borderRadius: '4px 4px',
    padding: '5px 10px',
    cursor: 'pointer',
    textTransform: 'inherit',
    fontSize: '1.0rem',
    fontWeight: 'normal',
    ':hover': {
      opacity: 0.7,
      transition: '300ms'
    }
  }

}

export default RoadMapDeliveryMan
