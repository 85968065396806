import React, { useEffect, useState } from "react";
import { useMapsLibrary, useMap } from "@vis.gl/react-google-maps";

const Direction = (props) => {
  const { deliveries, color, handleResult, isLoading } = props;

  const map = useMap();
  const routesLibrary = useMapsLibrary("routes");
  const [directionsService, setDirectionsService] = useState(null);
  const [directionsRenderer, setDirectionsRenderer] = useState(null);
  const [routes, setRoutes] = useState([]);
  const [routeIndex, setRouteIndex] = useState(0);
  const selected = routes[routeIndex];
  const leg = selected?.legs[0];

  const [init, setInit] = useState(false);
  const [deliveriesBis, setDeliveriesBis] = useState(deliveries);

  // Initialize directions service and renderer
  useEffect(() => {
    if (!routesLibrary || !map) return;
    setDirectionsService(new routesLibrary.DirectionsService());
    setDirectionsRenderer(new routesLibrary.DirectionsRenderer({ map }));
  }, [routesLibrary, map]);

  useEffect(() => {
    if (!directionsService || !directionsRenderer) return;

    let waypts = deliveries.map((element) => ({
      location: new window.google.maps.LatLng(
        element.delivery.lat,
        element.delivery.lng,
      ),
      stopover: true,
    }));

    const origin = new window.google.maps.LatLng(45.7613911, 4.9167728);
    let destination = origin;

    directionsService
      .route({
        origin: origin,
        waypoints: waypts,
        destination: destination,
        travelMode: window.google.maps.TravelMode.DRIVING,
        provideRouteAlternatives: true
      })
      .then(response => {
        directionsRenderer.setDirections(response);
        directionsRenderer.setOptions({
          draggable: false,
          markerOptions: {
            icon: {
              path: 'M-20,0a20,20 0 1,0 40,0a20,20 0 1,0 -40,0',
              fillColor: color,
              fillOpacity: 0.8,
              scale: 0.4,
              strokeColor: color,
              strokeWeight: 1
            }
          }
        });
        setRoutes(response.routes);
        handleResult(response);
      });

    return () => directionsRenderer.setMap(null);
  }, [directionsService, directionsRenderer]);

  useEffect(() => {
    if (directionsRenderer) {
      directionsRenderer.setRouteIndex(routeIndex);
    }
  }, [routeIndex, directionsRenderer]);

  if (!leg) return null;

  return (
    <div className="directions">
    </div>
  );
};

export default Direction;
