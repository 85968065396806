import {
  GET_ROADMAPS_COMPANION,
  GET_ROADMAPS_COMPANION_FAILED,
  GET_ROADMAPS_COMPANION_COMPLETED,
  UPDATE_DELIVERY,
  UPDATE_DELIVERY_COMPLETED,
  UPDATE_DELIVERY_FAILED,
  ADD_COMMENT,
  ADD_COMMENT_COMPLETED,
  ADD_COMMENT_FAILED,
  REMOVE_COMMENT,
  REMOVE_COMMENT_COMPLETED,
  REMOVE_COMMENT_FAILED,
  GET_DELIVERY_MEN,
  GET_DELIVERY_MEN_FAILED,
  GET_DELIVERY_MEN_COMPLETED,
  SET_CURRENT_DELIVERY_MAN,
  GET_MATERIALS,
  GET_MATERIALS_COMPLETED,
  GET_MATERIALS_FAILED,
  ADD_ORDER_MATERIAL,
  ADD_ORDER_MATERIAL_COMPLETED,
  ADD_ORDER_MATERIAL_FAILED,
  ADD_DELIVERY_MEDIA,
  ADD_DELIVERY_MEDIA_COMPLETED,
  ADD_DELIVERY_MEDIA_FAILED,
  DELETE_DELIVERY_MEDIA,
  DELETE_DELIVERY_MEDIA_COMPLETED,
  DELETE_DELIVERY_MEDIA_FAILED,
  UPDATE_ROADMAP_DELIVERY,
  UPDATE_ROADMAP_DELIVERY_COMPLETED,
  UPDATE_ROADMAP_DELIVERY_FAILED,
  UPDATE_ORDER_MATERIAL,
  UPDATE_ORDER_MATERIAL_COMPLETED,
  UPDATE_ORDER_MATERIAL_FAILED,
  SET_CURRENT_DELIVERY,
  GET_VEHICLES,
  GET_VEHICLES_COMPLETED,
  GET_VEHICLES_FAILED,
  UPDATE_ROADMAP,
  UPDATE_ROADMAP_COMPLETED,
  UPDATE_ROADMAP_FAILED,
} from "./actions";

const initialState = {
  data: [],
  isLoading: false,
  isInitialized: false,
  hasFailed: false,
  filters: {},
  deliveryMen: {
    data: [],
    isLoading: false,
    hasFailed: false,
    isInitialized: false,
  },
  materials: {
    data: [],
    isLoading: false,
    hasFailed: false,
    isInitialized: false,
  },
  currentDeliveryMan: null,
  currentDelivery: null,
  vehicles: {
    data: [],
    isLoading: false,
    hasFailed: false,
    isInitialized: false,
  },
};

const reducers = (state = initialState, action) => {
  switch (action.type) {
    case GET_ROADMAPS_COMPANION:
      return {
        ...state,
        isLoading: true,
      };
    case GET_ROADMAPS_COMPANION_COMPLETED:
      return {
        ...state,
        data: action.payload.result,
        filters: action.meta.params.filters,
        isLoading: false,
        isInitialized: true,
      };
    case GET_ROADMAPS_COMPANION_FAILED:
      return {
        ...state,
        isLoading: false,
        isInitialized: true,
        hasFailed: true,
      };
    case UPDATE_DELIVERY:
      return {
        ...state,
        isLoading: true,
      };
    case UPDATE_DELIVERY_COMPLETED:
      return {
        ...state,
        data: state.data.map((roadmap) => {
          const deliveries = roadmap.deliveries.map((delivery) => {
            if (delivery?.delivery?.id === action?.payload?.result?.id) {
              return {
                ...delivery,
                delivery: action.payload.result,
              };
            }
            return delivery;
          });
          return {
            ...roadmap,
            deliveries,
          };
        }),
        isLoading: false,
      };
    case UPDATE_DELIVERY_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ADD_COMMENT:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_COMMENT_COMPLETED:
      return {
        ...state,
        isLoading: false,
        data: state.data.map((roadmap) => {
          const deliveries = roadmap.deliveries.map((delivery) => {
            if (delivery.id === action.payload.result.id) {
              return action.payload.result;
            }
            return delivery;
          });
          return {
            ...roadmap,
            deliveries,
          };
        })
      };
    case ADD_COMMENT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case REMOVE_COMMENT:
      return {
        ...state,
        isLoading: true,
      };
    case REMOVE_COMMENT_COMPLETED:
      return {
        ...state,
        isLoading: false,
        data: state.data.map((roadmap) => {
          const deliveries = roadmap.deliveries.map((delivery) => {
            if (delivery.id === action
              .payload
              .result
              .id) {
              return action.payload.result;
            }
            return delivery;
          });
          return {
            ...roadmap,
            deliveries,
          };
        })
      };
    case REMOVE_COMMENT_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case GET_DELIVERY_MEN:
      return {
        ...state,
        deliveryMen: {
          ...state.deliveryMen,
          isLoading: true,
        },
      };
    case GET_DELIVERY_MEN_COMPLETED:
      return {
        ...state,
        deliveryMen: {
          data: action.payload.result,
          isLoading: false,
          isInitialized: true,
        },
      };
    case GET_DELIVERY_MEN_FAILED:
      return {
        ...state,
        deliveryMen: {
          ...state.deliveryMen,
          isLoading: false,
          hasFailed: true,
          isInitialized: true,
        },
      };
    case SET_CURRENT_DELIVERY_MAN:
      return {
        ...state,
        currentDeliveryMan: action.payload,
      };
    case GET_MATERIALS:
      return {
        ...state,
        materials: {
          ...state.materials,
          isLoading: true,
        },
      };
    case GET_MATERIALS_COMPLETED:
      return {
        ...state,
        materials: {
          data: action.payload.result,
          isLoading: false,
          isInitialized: true,
        },
      };
    case GET_MATERIALS_FAILED:
      return {
        ...state,
        materials: {
          ...state.materials,
          isLoading: false,
          hasFailed: true,
          isInitialized: true,
        },
      };
    case ADD_ORDER_MATERIAL:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_ORDER_MATERIAL_COMPLETED:
      return {
        ...state,
        isLoading: false,
        currentDelivery: state.currentDelivery ? {
          ...state.currentDelivery,
          delivery: {
            ...state.currentDelivery.delivery,
            info: {
              ...state.currentDelivery.delivery.info,
              materials: action.payload.result.orderMaterials,
            },
          },
        } : null,
      };
    case ADD_ORDER_MATERIAL_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case ADD_DELIVERY_MEDIA:
      return {
        ...state,
        isLoading: true,
      };
    case ADD_DELIVERY_MEDIA_COMPLETED:
      return {
        ...state,
        isLoading: false,
        data: state.data.map((roadmap) => {
          const deliveries = roadmap.deliveries.map((delivery) => {
            if (delivery.id === action.payload.result.id) {
              return action.payload.result;
            }
            return delivery;
          });
          return {
            ...roadmap,
            deliveries,
          };
        })
      };
    case ADD_DELIVERY_MEDIA_FAILED:
      return {
        ...state,
        isLoading: false,
      };
    case DELETE_DELIVERY_MEDIA:
      return {
        ...state,
        isLoading: true,
      };
    case DELETE_DELIVERY_MEDIA_COMPLETED:
      return {
        ...state,
        isLoading: false,
        data: state.data.map((roadmap) => {
          const deliveries = roadmap.deliveries.map((delivery) => {
            if (delivery.id === action.payload.result.id) {
              return action.payload.result;
            }
            return delivery;
          });
          return {
            ...roadmap,
            deliveries,
          };
        })
      };
    case DELETE_DELIVERY_MEDIA_FAILED:
      return {
        ...state,
        isLoading: false,
      };
      case UPDATE_ROADMAP_DELIVERY:
        return {
          ...state,
          isLoading: true,
        };
      case UPDATE_ROADMAP_DELIVERY_COMPLETED:
        return {
          ...state,
          data: state.data.map((roadmap) => {
            const deliveries = roadmap.deliveries.map((roadmapDelivery) => {
              if (roadmapDelivery.id === action.payload.result.id) {
                return action.payload.result;
              }
              return roadmapDelivery;
            });
            return {
              ...roadmap,
              deliveries,
            };
          }),
          currentDelivery: action.payload.result,
          isLoading: false,
        };
      case UPDATE_ROADMAP_DELIVERY_FAILED:
        return {
          ...state,
          isLoading: false,
        };
      case UPDATE_ORDER_MATERIAL:
        return {
          ...state,
          isLoading: true,
        };
      case UPDATE_ORDER_MATERIAL_COMPLETED:
        return {
          ...state,
          isLoading: false,
          currentDelivery: state.currentDelivery ? {
            ...state.currentDelivery,
            delivery: {
              ...state.currentDelivery.delivery,
              info: {
                ...state.currentDelivery.delivery.info,
                materials: state.currentDelivery.delivery.info.materials.map((material) => {
                  if (material.id === action.payload.result.id) {
                    return action.payload.result;
                  }
                  return material;
                }),
              },
            },
          } : null,
        };
      case UPDATE_ORDER_MATERIAL_FAILED:
        return {
          ...state,
          isLoading: false,
        };
      case SET_CURRENT_DELIVERY:
        return {
          ...state,
          currentDelivery: action.payload,
        };
      case GET_VEHICLES:
        return {
          ...state,
          vehicles: {
            ...state.vehicles,
            isLoading: true,
          },
        };
      case GET_VEHICLES_COMPLETED:
        return {
          ...state,
          vehicles: {
            ...state.vehicles,
            data: action.payload.result,
            isLoading: false,
            isInitialized: true,
          },
        };
      case GET_VEHICLES_FAILED:
        return {
          ...state,
          vehicles: {
            ...state.vehicles,
            isLoading: false,
            hasFailed: true,
            isInitialized: true,
          },
        };
      case UPDATE_ROADMAP:
        return {
          ...state,
          isLoading: true,
        };
      case UPDATE_ROADMAP_COMPLETED:
        return {
          ...state,
          isLoading: false,
          data: state.data.map((roadmap) => {
            if (roadmap.id === action.payload.result.id) {
              return action.payload.result;
            }
            return roadmap;
          }),
        };
      case UPDATE_ROADMAP_FAILED:
        return {
          ...state,
          isLoading: false,
        };
    default:
      return state;
  }
};

export default reducers;
