import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './styles.css';

const AnticipationTable = ({ title, orders, tags, utility, boldSeparatorColumns = [], onToggleProductNames = null }) => {

  // Calcul des totaux
  const calculateTotals = () => {
    const totals = { Autres: [] };
    tags.forEach(tag => {
      totals[tag.name] = [];
    });

    orders.forEach(order => {
      const products = order.tagProducts[utility] || {};
      Object.entries(products).forEach(([tagName, tagProducts]) => {
        if (Array.isArray(tagProducts)) {
          tagProducts.forEach(product => {
            const existingProduct = totals[tagName]?.find(p => p.genericId === product.genericId);
            if (existingProduct) {
              existingProduct.quantity += parseFloat(product.quantity) || 0;
            } else if (product.genericId) {
              totals[tagName]?.push({ ...product });
            }
          });
        }
      });

      // Gestion des "Autres"
      const autresProducts = order.tagProducts.autres?.Autres || [];
      if (Array.isArray(autresProducts)) {
        autresProducts.forEach(product => {
          const existingProduct = totals.Autres.find(p => p.genericId === product.genericId);
          if (existingProduct) {
            existingProduct.quantity += parseFloat(product.quantity) || 0;
          } else if (product.genericId) {
            totals.Autres.push({ ...product });
          }
        });
      }
    });

    return totals;
  };

  const totals = calculateTotals();

  // Détermine si un produit doit afficher son nom
  const shouldShowProductName = (tagName, products) => {
    // Si c'est la colonne Café
    if (!Array.isArray(products)) return false;
    
    // Si plusieurs produits différents dans la même colonne
    const uniqueProductIds = new Set(products.map(p => p.genericId));
    if (uniqueProductIds.size > 1) return true;
    
    // Si le produit apparaît dans plusieurs colonnes
    const productAppearances = new Set();
    orders.forEach(order => {
      const tagProducts = order.tagProducts[utility]?.[tagName];
      if (Array.isArray(tagProducts)) {
        tagProducts.forEach(product => {
          if (product.genericId) {
            productAppearances.add(product.genericId);
          }
        });
      }
    });
    
    return productAppearances.size > 1;
  };

  // Colonnes du tableau
  const columns = [
    { header: "H départ", key: "roadmapStartHour" },
    { header: "Presta", key: "orderNumber" },
    ...tags.map(tag => ({
      header: tag.name,
      key: tag.name
    })),
    { header: "Autres", key: "Autres" }
  ];

  const renderProductCell = (products, tagName) => {
    if (!Array.isArray(products)) return '';
    return products
      .filter(product => product && product.genericId)
      .map(product => {
        // Pour la colonne Autres et Sablés beurre, toujours afficher le nom du produit
        if (tagName === 'Autres' || tagName === 'Sablés beurre') {
          return `<span>${product.productName} - ${product.quantity}${product.unit ? ` ${product.unit}` : ''}</span>`;
        }
        // Pour les autres colonnes, afficher selon la logique existante
        const formattedProduct = shouldShowProductName(tagName, products)
          ? `<span>${product.productName} - ${product.quantity}${product.unit ? ` ${product.unit}` : ''}</span>`
          : `<span>${product.quantity}${product.unit ? ` ${product.unit}` : ''}</span>`;
        return formattedProduct;
      })
      .filter(Boolean)
      .join(' || ');
  };

  // Vérifie si une commande contient des produits dans les colonnes concernées
  const hasProducts = (order) => {
    // Vérifie les produits dans les colonnes de tags
    const hasTagProducts = tags.some(tag => {
      const products = order.tagProducts[utility]?.[tag.name];
      return Array.isArray(products) && products.length > 0;
    });

    // Vérifie les produits dans la colonne "Autres"
    const hasAutresProducts = Array.isArray(order.tagProducts.autres?.Autres) && 
                            order.tagProducts.autres.Autres.length > 0;

    return hasTagProducts || hasAutresProducts;
  };

  // Filtrer les commandes qui ont des produits
  const filteredOrders = orders.filter(hasProducts);

  return (
    <div className="anticipation-table">
      {onToggleProductNames && (
        <div className="no-print mb-2">
          <input
            type="checkbox"
            onChange={(e) => onToggleProductNames(e.target.checked)}
          />
          <label className="ml-2">Afficher le nom des produits</label>
        </div>
      )}
      
      <Table bordered className="mb-4">
        <thead>
          <tr>
            <th colSpan={columns.length} className="text-center">
              {title}
              {title === "Tableau des pièces Sucrées" && (
                <div className="text-right">
                  <div style={{ display: "flex", justifyContent: "flex-end", fontSize: "12px", gap: "15px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          backgroundColor: "green",
                          width: "15px",
                          height: "15px",
                          marginRight: "5px",
                        }}
                      />
                      <span>0 déchet + service</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          backgroundColor: "orange",
                          width: "15px",
                          height: "15px",
                          marginRight: "5px",
                        }}
                      />
                      <span>0 déchet</span>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div
                        style={{
                          backgroundColor: "violet",
                          width: "15px",
                          height: "15px",
                          marginRight: "5px",
                        }}
                      />
                      <span>Avec service</span>
                    </div>
                  </div>
                </div>
              )}
            </th>
          </tr>
          <tr>
            {columns.map((column, index) => (
              <th 
                key={column.key}
                className={boldSeparatorColumns.includes(column.header) ? 'border-right-bold' : ''}
                style={{ width: index === 0 ? '10%' : undefined }}
              >
                {column.header}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {filteredOrders.map((order, orderIndex) => (
            <tr 
              key={orderIndex}
              style={
                title === "Tableau des pièces Sucrées" 
                ? (order.isZeroWaste && order.withService
                    ? { backgroundColor: "green" }
                    : order.isZeroWaste
                    ? { backgroundColor: "orange" }
                    : order.withService
                    ? { backgroundColor: "violet" }
                    : {})
                : {}
              }
            >
              <td>{order.roadmapStartHour || ''}</td>
              <td className={boldSeparatorColumns.includes('Presta') ? 'border-right-bold' : ''}>
                <span style={{ fontWeight: 'bold', fontSize: '1.1em', color: 'blue' }}>
                  {order.orderNumber}
                </span>
              </td>
              {tags.map(tag => (
                <td 
                  key={tag.name}
                  className={boldSeparatorColumns.includes(tag.name) ? 'border-right-bold' : ''}
                  dangerouslySetInnerHTML={{
                    __html: renderProductCell(order.tagProducts[utility]?.[tag.name], tag.name)
                  }}
                />
              ))}
              <td
                dangerouslySetInnerHTML={{
                  __html: renderProductCell(order.tagProducts[utility]?.Autres, 'Autres')
                }}
              />
            </tr>
          ))}
          {/* Ligne des totaux */}
          <tr style={{ fontWeight: 'bold', backgroundColor: '#f2f2f2' }}>
            <td>Total</td>
            <td className={boldSeparatorColumns.includes('Presta') ? 'border-right-bold' : ''}></td>
            {tags.map(tag => (
              <td 
                key={tag.name}
                className={boldSeparatorColumns.includes(tag.name) ? 'border-right-bold' : ''}
                dangerouslySetInnerHTML={{
                  __html: renderProductCell(totals[tag.name], tag.name)
                }}
              />
            ))}
            <td
              dangerouslySetInnerHTML={{
                __html: renderProductCell(totals.Autres, 'Autres')
              }}
            />
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

AnticipationTable.propTypes = {
  title: PropTypes.string.isRequired,
  orders: PropTypes.array.isRequired,
  tags: PropTypes.array.isRequired,
  utility: PropTypes.string.isRequired,
  boldSeparatorColumns: PropTypes.arrayOf(PropTypes.string),
  onToggleProductNames: PropTypes.func
};

export default AnticipationTable; 