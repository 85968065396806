import React, { useState, useEffect } from "react";
import { Container, Col, Row, Button } from "react-bootstrap";
import LeMoulinApi from "app/universal/utils/LeMoulinApi";
import FakeDelivery from "app/deliveryTool/components/delivery/FakeDelivery";
import AnticipationTable from "./AnticipationTable.js";
import { css } from "emotion";
import moment from "moment";
import Helmet from "react-helmet";

/**
 * Gère les tableaux des anticipations
 * Concerne les commandes de boissons et de pièces sucrées
 * Dans boissons il y a des produits PR et Cocktail pour le tag Café et Thé sinon que du PDJ
 */
const Anticipation = () => {
  const [data, setData] = useState({ orders: [], tags: [], errors: [] });
  const [loading, setLoading] = useState(false);
  const [showBoisson, setShowBoisson] = useState(true);
  const [showSucree, setShowSucree] = useState(true);
  const [showBoissonPRTR, setShowBoissonPRTR] = useState(true);

  const queryString = require("query-string");
  const prm = queryString.parse(window.location.search);
  const date = new Date(prm.startDate);

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const result = await LeMoulinApi.request.anticipation.gets({
        date: date,
        tag_functions: ['anticipation_piece_sucree', 'anticipation_boisson', 'anticipation_boisson_prtr']
      });

      setData(result);
      setLoading(false);
    };
    fetchData();
  }, []);

  const handlePrint = () => {
    window.print();
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div>
      {loading ? (
        <Container>
          <FakeDelivery />
        </Container>
      ) : (
        <Col xs={12} sm={12} md={12}>
          <Row className="align-items-center mt-2 no-print">
            <Col xs={12} sm={4} md={6} className="text-left d-flex">
              <div>
                <input 
                  type="checkbox" 
                  checked={showBoisson} 
                  onChange={() => setShowBoisson(!showBoisson)} 
                /> Anticipation PDJ Boisson
              </div>
              <div>
                <input 
                  type="checkbox" 
                  checked={showSucree} 
                  onChange={() => setShowSucree(!showSucree)} 
                /> Anticipation PDJ Sucrée
              </div>
              <div>
                <input 
                  type="checkbox" 
                  checked={showBoissonPRTR} 
                  onChange={() => setShowBoissonPRTR(!showBoissonPRTR)} 
                /> Anticipation PR/TR Boisson
              </div>
            </Col>
            <Col xs={12} sm={4} md={6} className="text-right">
              <Button onClick={handlePrint}>Imprimer</Button>
            </Col>
          </Row>

          <Col xs={12} sm={12} md={12}>
            {data.errors.length > 0 && (
              <div className="alert alert-danger" role="alert">
                {data.errors.map((error, i) => (
                  <p key={i}>{error}</p>
                ))}
              </div>
            )}
          </Col>

          {date && (
            <Helmet bodyAttributes={{ style: "background-color : white" }}>
              <title>Anticipation {moment(date).format("DD/MM/YY")}</title>
            </Helmet>
          )}

          <Col xs={12} sm={12} md={12}>
            {showBoisson && (
              <div style={{ pageBreakAfter: 'always' }}>
                <h1 className={css(styles.title)}>
                  Anticipation PDJ Boisson du {capitalizeFirstLetter(moment(date).format("dddd DD/MM/YY"))}
                </h1>
                <AnticipationTable
                  title="Tableau des boissons"
                  orders={data.orders}
                  tags={data.tags.filter(tag => tag.utility === 'anticipation_boisson')}
                  utility="anticipation_boisson"
                  boldSeparatorColumns={["Presta", "Thé"]}
                />
              </div>
            )}

            {showSucree && (
              <div style={{ pageBreakAfter: 'always' }}>
                <h1 className={css(styles.title)}>
                  Anticipation PDJ Sucrée du {capitalizeFirstLetter(moment(date).format("dddd DD/MM/YY"))}
                </h1>
                <AnticipationTable
                  title="Tableau des pièces Sucrées"
                  orders={data.orders}
                  tags={data.tags.filter(tag => tag.utility === 'anticipation_piece_sucree')}
                  utility="anticipation_piece_sucree"
                  boldSeparatorColumns={["Presta", "B. praline", "Sablé", "Financier"]}
                />
              </div>
            )}

            {showBoissonPRTR && (
              <div>
                <h1 className={css(styles.title)}>
                  Anticipation PR/TR Boisson du {capitalizeFirstLetter(moment(date).format("dddd DD/MM/YY"))}
                </h1>
                <AnticipationTable
                  title="Tableau des boissons PR/TR"
                  orders={data.orders}
                  tags={data.tags.filter(tag => tag.utility === 'anticipation_boisson_prtr')}
                  utility="anticipation_boisson_prtr"
                  boldSeparatorColumns={["Presta"]}
                />
              </div>
            )}
          </Col>
        </Col>
      )}
    </div>
  );
};

const styles = {
  title: {
    color: "#b64e4e",
    textAlign: "center",
    fontSize: "1.6rem",
  },
};

export default Anticipation;
