export const ATSTenderCatalog = (data) => {
    return {
        id: data.id || null,
        items: ATSTenderCatalogItems(data.items),
        ranges: ATSTenderCatalogRanges(data.ranges),
    };
}

export const ATSTenderCatalogItems = (data) => {
    return data.map((item) => (ATSTenderCatalogItem(item)));
}

export const ATSTenderCatalogRanges = (data) => {
    return data.map((range) => (ATSTenderCatalogRange(range))).sort((a, b) => a.min - b.min);
}

export const ATSTenderCatalogItem = (data) => {
    return {
        id: data.id || null,
        name: data?.product_name || data?.group_name || data?.package_name || null,
        type: data?.product_id ? 'product' : data?.group_id ? 'group' : 'package',
        renammed: data?.name || null,
        product_id: data?.product_id || null,
        group_id: data?.group_id || null,
        package_id: data?.package_id || null,
        is_up_to_date: data?.is_up_to_date || false,
        is_active_item: data?.is_active_item || false,
        types: data?.types || null,
        pricings: data.pricings.map((pricing) => ({
            price: pricing?.price,
            range_id: pricing?.range_id || null,
            min_persons: pricing?.min_persons || null,
            max_persons: pricing?.max_persons || null,
            prestation_types: pricing?.prestation_types || [],
        }))
        .sort((a, b) => a.min_persons - b.min_persons),
        price: data?.price,
    }
}

export const ATSTenderCatalogRange = (data) => {
    return {
        id: data?.id ? parseInt(data.id) : null,
        min: data?.min_persons || null,
        max: data?.max_persons || null,
        prestation_types: data?.prestation_types || [],
    }
}

export const STATenderCatalog = (data) => {
    return {
        id: data.id || null,
        items: STATenderCatalogItems(data.items),
        ranges: STATenderCatalogRanges(data.ranges).sort((a, b) => a.min - b.min),
    };
}

export const STATenderCatalogItems = (data) => {
    return data.map((item) => (STATenderCatalogItem(item)));
}

export const STATenderCatalogRanges = (data) => {
    return data.map((range) => (STATenderCatalogRange(range)));
}

export const STATenderCatalogItem = (data) => {
    return {
        id: data?.id || null,
        product_id: data?.product_id || null,
        group_id: data?.group_id || null,
        package_id: data?.package_id || null,
        pricings: data.pricings.map((pricing) => ({
            price: pricing?.price,
            range: pricing?.range_id,
            min_persons: pricing?.min_persons || null,
            max_persons: pricing?.max_persons || null
        })).sort((a, b) => a.min_persons - b.min_persons),
        price: data?.price,
        name: data?.renammed || null,
    }
}

export const STATenderCatalogRange = (data) => {
    return {
        id: data.id || null,
        minPersons: data?.min || null,
        maxPersons: data?.max || null,
        prestationTypes: data?.prestation_types || [],
    }
}


