import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { useLocation, useParams } from "react-router-dom";
import useConnectedUser from "app/universal/hook/useConnectedUser";
import {
  selectIsRoadmapsLoading,
  selectDeliveryMen,
  selectCurrentDeliveryMan,
} from "state/roadmapsCompanion/selectors";
import {
  getRoadmapsCompanion,
  getDeliveryMen,
  getMaterials,
  getVehicles,
  setCurrentDelivery,
} from "state/roadmapsCompanion/actions";
import {
  addComment as addCommentAction,
  deleteComment as deleteCommentAction,
  updateRoadmapDelivery as updateRoadmapDeliveryAction,
  updateRoadmap as updateRoadmapAction,
} from "state/roadmapsCompanion/actions";

const useCompanion = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { roadmapId, deliveryId } = useParams();

  const params = new URLSearchParams(location.search);

  const dateParam = params.get("date");
  const date = dateParam ? new Date(dateParam) : null;

  const deliveryManIdParam = params.get("deliveryManId");
  const deliveryManId = deliveryManIdParam
    ? parseInt(deliveryManIdParam)
    : null;

  const { connectedUser } = useConnectedUser();

  const roadmaps = useSelector((state) => state.roadmaps);
  const isRoadmapsLoading = useSelector((state) =>
    selectIsRoadmapsLoading(state)
  );

  const deliveryMenState = useSelector((state) => state.roadmaps.deliveryMen);
  const deliveryMen = useSelector((state) => selectDeliveryMen(state));

  const vehiclesState = useSelector((state) => state.roadmaps.vehicles);
  const vehicles = useSelector((state) => state.roadmaps.vehicles.data);

  const materialsState = useSelector((state) => state.roadmaps.materials);
  const materials = useSelector((state) => state.roadmaps.materials.data);

  const currentDeliveryMan = useSelector((state) =>
    selectCurrentDeliveryMan(state)
  );

  const currentFilters = useSelector((state) => state.roadmaps.filters);

  const filteredDelivery = useSelector(
    (state) => state.roadmaps.currentDelivery
  );

  const currentDelivery = useSelector(
    (state) => state.roadmaps.currentDelivery
  );

  const [filteredRoadmap, setFilteredRoadmap] = useState(null);
  const [orderMaterials, setOrderMaterials] = useState([]);

  useEffect(() => {
    if ((connectedUser?.id || currentDeliveryMan?.id) && !isRoadmapsLoading) {
      let filters = {
        ...(date && { date: moment(date).format("YYYY-MM-DD") }),
        ...(roadmapId && { id: roadmapId }),
      };
      filters = {
        ...filters,
        deliveryMan:
          deliveryManId || currentDeliveryMan?.id || connectedUser?.id,
      };
      let launchDispatch = false;
      if (JSON.stringify(filters) !== JSON.stringify(currentFilters)) {
        launchDispatch = true;
      }

      if (launchDispatch) {
        dispatch(getRoadmapsCompanion(filters));
      }
    }
  }, [date, connectedUser, roadmapId, currentDeliveryMan, deliveryManId]);

  useEffect(() => {
    if (!deliveryMenState.isInitialized && !deliveryMenState.isLoading) {
      dispatch(getDeliveryMen());
    }
  }, [deliveryMenState]);

  useEffect(() => {
    if (!materialsState.isInitialized && !materialsState.isLoading) {
      dispatch(getMaterials());
    }
  }, [materialsState]);

  useEffect(() => {
    if (!vehiclesState.isInitialized && !vehiclesState.isLoading) {
      dispatch(getVehicles());
    }
  }, [vehiclesState]);

  useEffect(() => {
    if (roadmaps.data.length > 0) {
      const roadmap = roadmaps.data.find(
        (roadmap) => parseInt(roadmap.id) === parseInt(roadmapId)
      );
      setFilteredRoadmap(roadmap || null);
    }
  }, [roadmaps, roadmapId]);

  useEffect(() => {
    if (
      filteredRoadmap &&
      parseInt(filteredDelivery?.id) !== parseInt(deliveryId)
    ) {
      const delivery = filteredRoadmap.deliveries.find(
        (delivery) => parseInt(delivery.id) === parseInt(deliveryId)
      );
      dispatch(setCurrentDelivery(delivery));
    }
  }, [filteredRoadmap, deliveryId]);

  useEffect(() => {
    if (currentDelivery && currentDelivery.id) {
      setOrderMaterials(currentDelivery.delivery.info.materials);
    }
  }, [currentDelivery]);

  // Ajoute un commentaire et met à jour filteredDelivery
  const addComment = async (deliveryId, formData) => {
    return await dispatch(addCommentAction(deliveryId, formData)).then(
      (resp) => {
        if (resp?.comments) {
          dispatch(
            setCurrentDelivery({
              ...filteredDelivery,
              delivery: {
                ...filteredDelivery.delivery,
                comments: resp.comments,
              },
            })
          );
        }
        return resp;
      }
    );
  };

  const deleteComment = async (deliveryId, commentId) => {
    return await dispatch(deleteCommentAction(deliveryId, commentId)).then(
      (resp) => {
        if (resp?.comments) {
          dispatch(
            setCurrentDelivery({
              ...filteredDelivery,
              delivery: {
                ...filteredDelivery.delivery,
                comments: resp.comments,
              },
            })
          );
        }
        return resp;
      }
    );
  };

  const updateRoadmapDelivery = async (deliveryId, data) => {
    return await dispatch(updateRoadmapDeliveryAction(deliveryId, data)).then(
      (resp) => {
        if (resp) {
          dispatch(
            setCurrentDelivery({
              ...filteredDelivery,
              delivery: {
                ...filteredDelivery.delivery,
                ...resp,
              },
            })
          );
        }
        return resp;
      }
    );
  };

  const updateRoadmapVehicle = async (roadmapId, vehicleId) => {
    return await dispatch(updateRoadmapAction(roadmapId, {
      vehicleId: vehicleId,
    })).then(
      (resp) => {
        return resp;
      }
    );
  };

  return {
    roadmaps,
    isLoading: isRoadmapsLoading || deliveryMenState.isLoading,
    deliveryMen,
    currentDeliveryMan,
    materials,
    orderMaterials,
    filteredRoadmap,
    filteredDelivery,
    addComment,
    deleteComment,
    updateRoadmapDelivery,
    vehicles,
    updateRoadmapVehicle,
  };
};

export default useCompanion;
