import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { css } from "emotion";
import moment from "moment";

import Translate from "app/universal/utils/Translate";
import UpdateDataApi from "app/universal/utils/UpdateDataApi";
import UrlQuotation from "app/universal/utils/UrlQuotation";
import Reviews from "app/universal/utils/Reviews";

const Recovery = (props) => {
  const {
    delivery,
    colorDeliveryMan,
    filters,
    handler,
    getUrl,
    theme,
    roadmapId,
    handleReallocation
  } = props;

  const styles = {
    recovery: {
      background: "#2e3032",
      margin: "10px 0",
      color: "white",
      transition: "300ms",
      border: "1px #dedede solid",
      position: "relative",
      ":before": {
        content: '""', // Correction des guillemets
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        width: "5px",
        height: "100%",
        background: delivery?.recovery_done ? "green" : "red",
      }
    },
    recoveryPrint: {
      background: "white",
      margin: "10px 0",
      color: "black",
      transition: "300ms",
      border: "1px #dedede solid",
    },
    statut: {
      textTransform: "uppercase",
      fontSize: "0.6rem",
      marginBottom: "0rem",
      "@media print": {
        fontSize: "1.4rem",
      },
    },
    col: {
      padding: "0.5rem",
      borderRight: "1px #dedede solid",
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
    },
    verticalAlign: {
      display: "flex",
      flexDirection: "row",
    },
    para: {
      marginBottom: "0rem",
      fontSize: "0.8rem",
      "@media print": {
        fontSize: "1.6rem",
      },
      "@media (max-width: 767px)": {
        textAlign: 'left'
      },
    },
    center: {
      textAlign: "center",
    },
    noImg: {
      backgroundColor: "#dedede",
      textAlign: "center",
      color: "#ccc",
      wordBreak: "break-word",
    },
    img: {
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      minHeight: "100px",
      textAlign: "center",
    },
    divisor: {
      height: "1px",
      backgroundColor: "#dedede",
      margin: "1.0rem 0",
    },
    blockColor: {
      height: "25px",
      width: "25px",
      position: "absolute",
      top: "0",
      left: "0",
      color: "white",
      textShadow: "1px 1px 2px grey",
      textAlign: "center",
    },
    blockColorPrint: {
      height: "25px",
      width: "25px",
      position: "absolute",
      top: "0",
      right: "0",
      color: "white",
      textShadow: "1px 1px 2px grey",
      textAlign: "center",
      "@media print": {
        fontSize: "2.2rem",
      },
    },
    comment: {
      borderTop: "1px #dedede solid",
      fontSize: "0.8rem",
      "@media print": {
        fontSize: "1.6rem",
      },
    },
    warning: {
      color: "red",
    },
    company: {
      textTransform: "uppercase",
      color: "#429853",
      fontWeight: "bold",
      fontSize: "0.8rem",
      "@media print": {
        fontSize: "1.6rem",
      },
    },
    reallocation: {
      position: "absolute",
      bottom: 0,
      right: 0,
      fontSize: "0.8rem",
    },
  };

  const reallocation = (delivery) => {
    if (!delivery.recovery) {
      return alert("Ce n'est pas une récupération !");
    }
    else {
      if (window.confirm("Voulez-vous vraiment réaffecter cette livraison ?")) {
        handleReallocation();
      }
    }
  }
  return (
    <div>
      {theme === "light" ? (
        <Container
          className={css(styles.recovery, {
            border: filters.showDelivery === delivery.id && "3px green solid",
          })}
          name={"delivery-" + delivery.id}
          fluid
        >
          <Row className={css(styles.verticalAlign)}>
            <Col xs={12} md={12} className={css(styles.col)}>
              
              <UrlQuotation url={delivery.info.quotation_url} sheetRoad={`/feuille-de-route/${delivery.info.order_id}`} info={delivery.info} prestation={delivery.prestation_type} date={delivery.delivery_date}/>
              <h3 className={css(styles.statut)}>
                {!delivery.info.grouped_order_id &&
                  <p className="text-center" style={{ color: "red"}}>Ancien système, merci de migrer cette commande sur le nouveau système</p> 
                }
                Récupération <Translate item={delivery.prestation_type} /> du
                {moment(filters.date).format("YYYY-MM-DD") !==
                moment(delivery.delivery_date).format("YYYY-MM-DD") ? (
                  <span className={css(styles.warning)}>
                    {" "}
                    {moment(delivery.delivery_date).format("D-M-Y")}
                  </span>
                ) : (
                  " " + moment(delivery.delivery_date).format("D-M-Y")
                )}{" "}
                - {delivery.info && delivery.info.numbering && delivery.info.numbering} -{" "}
                <b>
                  {delivery.firstname} {delivery.lastname}
                </b>
              </h3>
              <p className={css(styles.para)}>
                <span className={css(styles.company)}>{delivery.company}</span>
                <br />
                {delivery.street_number} {delivery.street}{" "}
                {delivery.postal_code} {delivery.city}
                <br />
                <i>{delivery.address_complement}</i>
                <br />
                <br />
              </p>
              <Row noGutters>
                <Col xs={12} md={4}>
                  <p className={css(styles.para)}>Récupération :</p>
                </Col>
                <Col xs={12} md={4}>
                  <UpdateDataApi
                    value={delivery.end_at}
                    id={delivery.id}
                    name={"end_at"}
                    api={"delivery"}
                    input={"times"}
                    handler={handler}
                  />
                </Col>
              </Row>
              {/* <Row noGutters>
                <Col xs={12} md={4}>
                  <p className={css(styles.para)}>Récupération faite :</p>
                </Col>
                <Col xs={12} md={4}>
                  <UpdateDataApi
                    value={delivery.recovery_done}
                    id={delivery.id}
                    name={"recoveryDone"}
                    api={"delivery"}
                    input={"checkbox"}
                    handler={handler}
                  />
                </Col>
              </Row> */}
              <Row noGutters>
                <Col xs={12} md={12}>
                  <UpdateDataApi
                    value={delivery.recover_information}
                    id={delivery.id}
                    name={"recover_information"}
                    api={"delivery"}
                    input={"text"}
                    placeholder={"Ajouter un commentaire..."}
                    handler={handler}
                  />
                </Col>
                {roadmapId !== null && 
                  <Button
                    className={css(styles.reallocation)}
                    onClick={() => {
                      reallocation(delivery)
                    }}
                  >
                    A réattribuer
                  </Button>
                }
              </Row>
            </Col>
          </Row>
        </Container>
      ) : theme === "print" ? (
        <Container className={css(styles.recoveryPrint)} fluid>
          <Row className={css(styles.verticalAlign)}>
            <Col xs={12} md={6} className={css(styles.col)}>
              <h3 className={css(styles.statut)}>Récupération</h3>
              <p className={css(styles.para)}>
                <span className={css(styles.company)}>{delivery.company}</span>
                <br />
                {delivery.street_number} {delivery.street}{" "}
                {delivery.postal_code} {delivery.city}
                <br />
                <i>{delivery.address_complement}</i>
                <br />
                <i>
                  {delivery.firstname} {delivery.lastname} / <a href={`tel:${delivery.phone}`}>{delivery.phone}</a>
                  <br />
                </i>
              </p>
            </Col>
            <Col xs={12} md={3} className={css(styles.col)}>
              <p className={css(styles.para, styles.center)}>
                <Translate item={delivery.prestation_type} /> - {delivery.info && delivery.info.numbering && delivery.info.numbering}
                <br /> fait le {moment(delivery.delivery_date).format("D-M-Y")}
              </p>
            </Col>
            <Col xs={12} md={3} className={css(styles.col, styles.center)}>
              <p className={css(styles.para, styles.center)}>
                Récupération à partir de :{" "}
                {delivery.end_at
                  ? moment(delivery.end_at).format("H:mm")
                  : "NC"}
              </p>
              {/* <div className={css(styles.divisor)}></div>
              <UpdateDataApi
                label={"Récupération faite"}
                value={delivery.recovery_done}
                id={delivery.id}
                name={"recoveryDone"}
                api={"delivery"}
                input={"checkbox"}
                handler={handler}
              /> */}
            </Col>
            {delivery.recover_information && (
              <Col xs={12} className={css(styles.col, styles.comment)}>
                Commentaire récupération : {delivery.recover_information}
              </Col>
            )}
          </Row>
        </Container>
      ) : (
        <Container
          className={css(styles.recovery, {
            border: filters.showDelivery === delivery.id && "3px green solid",
          })}
          name={"delivery-" + delivery.id}
          fluid
        >
          <Row className={css(styles.verticalAlign)}>
            <Col xs={12} md={6} className={css(styles.col)}>
              <UrlQuotation url={delivery.info.quotation_url} sheetRoad={`/feuille-de-route/${delivery.info.order_id}`} info={delivery.info} prestation={delivery.prestation_type} date={delivery.delivery_date}/>
              <h3 className={css(styles.statut)}>Récupération</h3>
              <p className={css(styles.para)}>
                <span className={css(styles.company)}>{delivery.company}</span>
                <br />
                {delivery.street_number} {delivery.street}{" "}
                {delivery.postal_code} {delivery.city}
                <br />
                <i>{delivery.address_complement}</i>
                <br />
                <br />
                <b>
                  {delivery.firstname} {delivery.lastname}
                </b>
                <br />
                {delivery.phone}
              </p>
            </Col>
            <Col xs={12} md={3} className={css(styles.col)}>
              <p className={css(styles.para, styles.center)}>
                <Translate item={delivery.prestation_type} />
                <br />
                {delivery.info && delivery.info.numbering && delivery.info.numbering} - fait le{" "}
                {moment(filters.date).format("YYYY-MM-DD") !==
                moment(delivery.delivery_date).format("YYYY-MM-DD") ? (
                  <span className={css(styles.warning)}>
                    {" "}
                    {moment(delivery.delivery_date).format("D-M-Y")}
                  </span>
                ) : (
                  " " + moment(delivery.delivery_date).format("D-M-Y")
                )}
              </p>
            </Col>
            <Col xs={12} md={3} className={css(styles.col, styles.center)}>
              <Reviews note={delivery.note} />
              <div style={{ color: delivery?.recovery_done ? "green" : "red"}}>
              {roadmapId && <UpdateDataApi
                label={"Récupération faite"}
                value={delivery.recovery_done || false}
                id={delivery.id}
                name={"recoveryDone"}
                api={"delivery"}
                input={"checkbox"}
                handler={handler}
              />}
              </div>
              <div className={css(styles.divisor)}></div>
              <p className={css(styles.para, styles.center)}>
                Récupération à partir de :
              </p>
              <UpdateDataApi
                value={delivery.end_at}
                id={delivery.id}
                name={"end_at"}
                api={"delivery"}
                input={"times"}
                handler={handler}
              />
            </Col>
            <Col xs={12} className={css(styles.col, styles.comment)}>
              <UpdateDataApi
                value={delivery.recover_information}
                id={delivery.id}
                name={"recover_information"}
                api={"delivery"}
                input={"text"}
                placeholder={"Ecrire un commentaire..."}
                handler={handler}
              />
            </Col>
          </Row>
        </Container>
      )}
    </div>
  );
};



export default Recovery;
