import React, { useEffect } from "react";
import Layout from "app/companion/components/layout/Layout";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import Menu from "app/companion/pages/delivery/Menu";
import DeliveryTitle from "app/companion/pages/delivery/DeliveryTitle";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector, useDispatch } from "react-redux";
import { fetchOrder } from "state/prestationRoadSheet/actions";
import {
  faUser,
  faMapPin,
  faComment,
  faParking,
} from "@fortawesome/free-solid-svg-icons";
import useCompanion from "app/companion/useCompanion";
import Waze from "app/universal/assets/images/waze-picto.png";
import { APIProvider, Map, AdvancedMarker } from "@vis.gl/react-google-maps";
import RoadSheetModal from "app/companion/pages/delivery/detail/RoadSheetModal";
import { Link } from "react-router-dom";


// La feuille de livraison est une synthese du carnet d'adresse + info de la commande
const DeliverySheet = () => {
  const { filteredRoadmap, filteredDelivery } = useCompanion();
  const dispatch = useDispatch();
  const order = useSelector((state) => state.prestationRoadSheet.data.order);
  const isLoading = useSelector((state) => state.prestationRoadSheet.isLoading);
  const googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  useEffect(() => {
    if (
      filteredDelivery?.delivery?.info.order_id &&
      (!order ||
        parseInt(order.id) !==
          parseInt(filteredDelivery?.delivery?.info.order_id))
    ) {
      dispatch(
        fetchOrder(
          filteredDelivery.delivery.info.order_id,
          filteredDelivery.delivery.info.order_type
        )
      );
    }
  }, [dispatch, filteredDelivery, order]);

  let wazeUrl = "";
  if (
    filteredDelivery &&
    filteredDelivery?.delivery?.lat &&
    filteredDelivery?.delivery?.lng
  ) {
    wazeUrl = `https://waze.com/ul?ll=${filteredDelivery.delivery.lat},${filteredDelivery.delivery.lng}&navigate=no`;
  }

  const apiUrl = process.env.REACT_APP_API_URL;

  const options = {
    streetViewControl: false, // Désactiver le contrôle Street View
    mapTypeControl: true,
    fullscreenControl: false
  };
  
  const formatPhoneNumber = (phone) => {
    return phone.replace(/\D/g, '').slice(-10);
  };
  
  return (
    (filteredDelivery &&
    filteredRoadmap) ? (
      <Layout
        backButton
        date={moment(filteredDelivery.delivery.delivery_date).format(
          "YYYY-MM-DD"
        )}
      >
        <>
          <Container fluid={true}>
            <>
              <DeliveryTitle/>
              <h5 className="text-center my-3">FEUILLE DE LIVRAISON</h5>
              <hr />
              <APIProvider apiKey={googleMapsApiKey}>
                <Map
                  mapId="8f0b8aa9cd5906b"
                  style={{ width: "100%", height: "200px" }}
                  defaultCenter={{
                    lat: filteredDelivery?.delivery?.lat || 0,
                    lng: filteredDelivery?.delivery?.lng || 0,
                  }}
                  defaultZoom={15}
                  scrollwheel={false}
                  options={options}
                >
                  <AdvancedMarker
                    position={{
                      lat: filteredDelivery?.delivery?.lat || 0,
                      lng: filteredDelivery?.delivery?.lng || 0,
                    }}
                  />
                </Map>
              </APIProvider>
              <Card className="mt-2">
                <Card.Body>
                  <Row className="align-items-center" noGutters>
                    <Col xs={filteredDelivery.recovery ? 6 : 4} style={{ borderRight: "1px solid #ddd", textAlign: "center" }}>
                      <small>
                        {filteredDelivery.recovery ? (
                          <>
                            <b>{order?.prestation_type_fr}</b>
                            <br />
                            {filteredDelivery.delivery.info.numbering} du{" "}
                            {moment(
                              filteredDelivery.delivery.delivery_date
                            ).format("D/M/Y")}
                            {filteredDelivery?.delivery?.delivery_delivery_man && <>
                              <br />livré par {filteredDelivery.delivery.delivery_delivery_man.firstname}</>}
                          </>
                        ) : (
                          <>
                            <b>{order?.prestation_type_fr}</b>
                            <br />
                            {filteredDelivery.delivery.person} pers
                          </>
                        )}
                        <br />
                        {order?.delivery?.blanket
                          ? "Avec Nappe"
                          : "Sans nappe"}
                        
                        {order?.delivery?.service && 
                          " / Avec service "
                        }
                      </small>
                    </Col>
                    {!filteredDelivery.recovery && (
                      <Col
                        xs={4}
                        style={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        <small>
                          Arrivée du Moulin entre :<br />
                          <b>
                            {order?.delivery?.arrived_at_start
                              ? moment(
                                  order?.delivery?.arrived_at_start
                                ).format("HH:mm")
                              : "NC"}{" "}
                            et{" "}
                            {order?.delivery?.arrived_at_end
                              ? moment(order?.delivery?.arrived_at_end).format(
                                  "HH:mm"
                                )
                              : "NC"}
                          </b>
                        </small>
                      </Col>
                    )}
                    {!filteredDelivery.recovery && (
                      <Col
                        xs={4}
                        style={{
                          textAlign: "center",
                          borderRight: "1px solid #ddd",
                        }}
                      >
                        <small>
                          Début événement :<br />{" "}
                          <b>
                            {order?.delivery?.start_at
                              ? moment(order?.delivery?.start_at).format(
                                  "HH:mm"
                                )
                              : "NC"}
                          </b>
                        </small>
                      </Col>
                    )}
                    {filteredDelivery.recovery && (
                      <Col xs={6} style={{ textAlign: "center" }}>
                        <small>
                          {filteredDelivery.delivery.person} pers<br/>
                          Récupération à partir de :<br />{" "}
                          <b>
                            {order?.delivery?.end_at
                              ? moment(order?.delivery?.end_at).format("HH:mm")
                              : "NC"}
                          </b>
                        </small>
                      </Col>
                    )}
                  </Row>
                </Card.Body>
              </Card>
              <Card className="mt-2">
                <Card.Body>
                  <Row className="align-items-center" noGutters>
                    <Col xs={1}>
                      <FontAwesomeIcon icon={faUser} />
                    </Col>
                    <Col xs={11}>
                      {filteredDelivery.delivery.company}
                      <br />
                      {filteredDelivery.delivery.firstname} {filteredDelivery.delivery.lastname}
                      <br />
                      <a href={`tel:${formatPhoneNumber(filteredDelivery.delivery.phone)}`}>{filteredDelivery.delivery.phone}</a> / {""}
                      {filteredDelivery.delivery.phone_secondary &&
                        <a href={`tel:${formatPhoneNumber(filteredDelivery.delivery.phone_secondary)}`}>{filteredDelivery.delivery.phone_secondary}</a>
                      }
                      <br/><b>NUMERO UTILE :</b>
                      <br />
                      {order?.delivery?.delivery_address_book?.useful_number ||
                        "NC"}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card className="mt-2">
                <Card.Body>
                  <Row noGutters>
                    <Col xs={1}>
                      <FontAwesomeIcon icon={faMapPin} />
                    </Col>
                    <Col>
                      <b>{filteredDelivery.delivery.company}</b><br />
                      {filteredDelivery.delivery.street_number}{" "}
                      {filteredDelivery.delivery.street}
                      <br />
                      {filteredDelivery.delivery.postal_code}{" "}
                      {filteredDelivery.delivery.city}
                      <br />
                      {filteredDelivery.delivery.address_complement}
                    </Col>
                    <Col className="text-right" style={{ width: "70px" }}>
                      <Button
                        href={wazeUrl}
                        className="waze-button"
                        variant="outline-info"
                        target="_blank"
                      >
                        <img src={Waze} />
                      </Button>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card className="mt-2">
                <Card.Body>
                  <Row noGutters>
                    <Col xs={1}>
                      <FontAwesomeIcon icon={faComment} />
                    </Col>
                    <Col xs={11}>
                      <b>LIVRAISON :</b>
                      <br />
                      {order?.delivery?.delivery_information
                        ? order?.delivery?.delivery_information
                        : "NC"}
                      <br />
                      <>
                        <b>RÉCUPÉRATION :</b>
                        <br />
                        {order?.delivery?.recover_information
                          ? order?.delivery?.recover_information
                          : "NC"}
                      </>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              <Card className="mt-2">
                <Card.Body>
                  <Row noGutters>
                    <Col xs={1}>
                      <FontAwesomeIcon icon={faParking} />
                    </Col>
                    <Col xs={11}>
                      <b>PARKING</b>
                      <br />
                      {order?.delivery?.delivery_address_book
                        ?.parking_information || "NC"}
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
              {order?.delivery?.delivery_address_book?.delivery_address_medias.map(
                (media, index) => (
                  <Card className="mt-2" key={index}>
                    <Card.Body>
                      <Row noGutters>
                        <Col xs={12}>
                          <b>{media.title}</b>
                          <br />
                          <img
                            src={`${apiUrl}/${media.media.path}`}
                            alt={media.title}
                            style={{ width: "100%" }}
                          />
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                )
              )}
              <br />
              {/* Lorsqu'on a du service alors on affiche la feuille de route en complément */}
              {order?.delivery?.service && (
                <RoadSheetModal
                  order={order}
                  rmDelivery={filteredDelivery}
                  roadmap={filteredRoadmap}
                  isLoading={isLoading}
                />
              )}
            </>
          </Container>
          <Menu
            roadmap={filteredRoadmap && filteredRoadmap}
            delivery={filteredDelivery && filteredDelivery}
            comments={filteredDelivery?.delivery?.comments?.length}
          />
        </>
      </Layout>
    )
    :
    (
      <p>Cette livraison n'existe pas.
        <Link to={`/companion`} style={{ textDecoration: 'none' }}>
          <Button variant="secondary" className="mt-3" block>
              Retourner à l'accueil
          </Button>
        </Link>
      </p>
    )
  );
};

export default DeliverySheet;
